import './Business-home.css';

import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { callApi } from '../../api/API';
import BankInformation from '../../components/bank-information';
import BusinessProfile from '../../components/business-profile';
import Customers from '../../components/customers/Customers';
import Details from '../../components/details';
import Rewards from '../../components/rewards/Rewards';

function BoxPlaceholder({ show, children }) {
  if (!show) return null;

  return <div className='box-placeholder'>{children}</div>;
}

const BusinessHome = ({ location }) => {
  const [subscriptionPurchased, setSubscriptionPurchased] = React.useState(null);
  const [btnLoading, setBtnLoading] = React.useState({
    btn1: false,
    btn2: false,
    btn3: false,

  });

  const fetchSubscription = async () => {
    const token = Cookies.get('authtoken');
    callApi(`/stripe/subscription_detail`, 'POST', undefined, `Bearer ${token}`)
      .then((data) => {
        if (!data.is_subcription) setSubscriptionPurchased(false);
        if (data?.subscription?.status) setSubscriptionPurchased(true);
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  };

  const purchaseSubscription = (btn) => {
    setBtnLoading((prev) => ({ ...prev, [btn]: true }));
    const token = Cookies.get('authtoken');
    callApi('/users/purchase-subscription', 'POST', undefined, `Bearer ${token}`)
      .then((data) => {
        if (data?.url) window.location.href = data.url;
      })
      .catch((err) => {
        console.log(err?.response?.data);
      }).finally(() => setBtnLoading({
        btn1: false,
        btn2: false,
        btn3: false,

      }))
  };

  useEffect(fetchSubscription, []);

  return (
    <>
      <div className='col-xl-11 mx-auto other-container'>
        <div className='row '>
          <div className='col-xl-6  mb-4'>
            <div className='bg-white secttion-container w-100 h-100 d-flex flex-column justify-content-between'>
              <Customers query={location.search} />

              <BoxPlaceholder show={subscriptionPurchased !== true}>
                <p className='content'>
                  Invite upto 100 constomers via email to your Mom n Pop Hub Mobile app listing.
                </p>
                {!btnLoading.btn1 ? <button type='button' className='action-btn' onClick={() => purchaseSubscription('btn1')}>
                  Upgrade
                </button> :
                  <Loader type="Bars" color="#FF992E" height={32} />}

              </BoxPlaceholder>
            </div>
          </div>
          <div className='col-xl-6 mb-4'>
            <div className='bg-white secttion-container w-100 h-100 d-flex flex-column justify-content-between'>
              <Details />

              <BoxPlaceholder show={subscriptionPurchased !== true}>
                <p className='content'>
                  Sell exclusive deals to new & existing customers at 0% commission (payment
                  processor fees applies).
                </p>

                {!btnLoading.btn2 ? <button type='button' className='action-btn' onClick={() => purchaseSubscription('btn2')}>
                  Upgrade
                </button> :
                  <Loader type="Bars" color="#FF992E" height={32} />}
              </BoxPlaceholder>
            </div>
          </div>
          {/* Rewards */}

          {/*
          <div className='col-xl-6  mb-4'>
            <div className='bg-white secttion-container w-100 h-100 d-flex flex-column justify-content-between'>
              <Rewards />

              <BoxPlaceholder show={subscriptionPurchased !== true}>
                <p className='content'>
                  Create Rewards for the customers.
                </p>
                {!btnLoading.btn4 ? <button type='button' className='action-btn' onClick={() => purchaseSubscription('btn4')}>
                  Upgrade
                </button> :
                  <Loader type="Bars" color="#FF992E" height={32} />}

              </BoxPlaceholder>
            </div>
          </div>
        */}

          <div className='col-xl-6  mb-4'>
            <div className='bg-white secttion-container w-100 h-100'>
              <BankInformation />

              <BoxPlaceholder show={subscriptionPurchased !== true}>
                <p className='content'>Please buy subscription to enable the features.</p>

                {!btnLoading.btn3 ? <button type='button' className='action-btn' onClick={() => purchaseSubscription('btn3')}>
                  Buy Now
                </button> :
                  <Loader type="Bars" color="#FF992E" height={32} />}
              </BoxPlaceholder>
            </div>
          </div>
          <div className='col-xl-6  mb-4'>
            <div className='bg-white secttion-container w-100 h-100'>
              <BusinessProfile subscriptionPurchased={subscriptionPurchased} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessHome;
